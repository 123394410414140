@font-face {
    font-family: 'OakSans';
    font-style: normal;
    font-weight: 800;
    src: local('OakSans'), url('../fonts/AreaExtraBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'OakSans';
    font-style: normal;
    font-weight: 700;
    src: local('OakSans'), url('../fonts/AreaBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'OakSans';
    font-style: normal;
    font-weight: 600;
    src: local('OakSans'), url('../fonts/AreaSemiBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'OakSans';
    font-style: normal;
    font-weight: 500;
    src: local('OakSans'), url('../fonts/AreaInktrapBold.otf') format('opentype');
  }


  @font-face {
    font-family: 'OakSans';
    font-style: normal;
    font-weight: 300;
    src: local('ProximaNov'), url('../fonts/AreaInktrapSemibold.otf') format('opentype');
  }
